import React, { useState } from 'react';
import "./header.css";
import { Link, useLocation } from 'react-router-dom';

function Header() {
  const [isMobileNav, setIsMobileNav] = useState(false);
  const location = useLocation();

  const toggleMobileNav = () => {
    setIsMobileNav(!isMobileNav);
  };

  const toggleDropdown = (e) => {
    if (isMobileNav) {
      e.preventDefault();
    }
  };

  const links = [
    {
      to: "/",
      name: "Home"
    },
    {
      to: "/about",
      name: "About Us"
    },
    {
      to: "/news",
      name: "News"
    },
    {
      to: "/article",
      name: "Articles"
    },
    {
      to: "/training",
      name: "Training & CPD"
    },
    {
      to: "/events",
      name: "Events"
    },
    {
      to: "/resources",
      name: "Resources"
    },
    // {
    //   to: "/publishing",
    //   name: "Publishing"
    // }
  ];

  return (
    <>
      <header id="header" className="d-flex align-items-center">
        <div className="container d-flex justify-content-between align-items-center">
          <div className="logo">
            <h1>
              <Link to="/">AMCed</Link>
            </h1>
          </div>
          <nav id="navbar" className={`navbar ${isMobileNav ? 'navbar-mobile' : ''}`}>
            <ul>
              {
                links.map(l => (
                  <li key={l.to}>
                    <Link className={`${location.pathname === l.to && "active"}`} to={l.to} onClick={e => setIsMobileNav(false)}>
                      {l.name}
                    </Link>
                  </li>
                ))
              }
            </ul>
            <i className={`bi ${isMobileNav ? 'bi-x' : 'bi-list'} mobile-nav-toggle`} onClick={toggleMobileNav} />
          </nav>
          <div className="h-100 d-flex justify-content-center align-items-center user-actions">
            <Link
              to="/contact"
              title="Contact Us"
              className="btn no-shadow ms-1"
              style={{
                backgroundColor: "#ffa114",
                color: "#fff",
                borderRadius: 5,
                height: 35,
                fontSize: 14,
                letterSpacing: ".5px",
                fontWeight: 550
              }}
            >
              Contact Us
            </Link>
          </div>
        </div>
      </header>
    </>

  )
}

export default Header;