import React, { useEffect, useState } from "react";
import "./about.css";
import SMILING_BG from "../../assets/img/about/lady.png";
import SUPPORT from "../../assets/img/about/amy-hirschi-K0c8ko3e6AA-unsplash.png";
import PageInfo from "../../components/layout/pageInfo/PageInfo";
import { getList, imageBaseUrl } from "../../helper";
import EventList from "../event/EventList";

const About = ({ image }) => {
  const [eventList, setEventList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getEventData();
  }, []);

  const getEventData = async () => {
    try {
      setLoading(true);
      const url = `/admin/get/event/v1?limit=3&neId=`;
      const data = await getList(url);
      setEventList(data.data);
      setLoading(false);
    } catch (error) {
      console.error("List error", error);
      setLoading(false);
    }
  };
  return (
    <>
      <main id="main">
        {/* ======= Page Info ======= */}
        <PageInfo
          title="About Us"
          imgUrl={`${imageBaseUrl}${image}`}
          description="Association of Muslim Chaplains in Education is an independent, national non-profit organisation which provides support, advice and training for Muslim chaplains working in the education sector."
        />
        {/* End Page Info */}
        {/* ======= About Section ======= */}
        <section id="aboutus" className="aboutus">
          <div className="container-lg">
            <div className="row justify-content-center aboutus-row">
              <div className="d-none d-md-block col-12 col-md-5 col-lg-4">
                <div className="left-img d-flex align-items-center justify-content-start px-4 h-100 w-100">
                  <img src={SMILING_BG} alt="" className="img-cute-girl" />
                </div>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center">
                <div className="section-info text-left p-0">
                  <h1>How did AMCed come about?</h1>
                  <p className="mt-4">Led by founding director Dr Asgar Halim Rajput, AMCed was launched in 2008 to be the voice of Muslim chaplains to offer support, provide opportunities for skills development through seminars, training, CPD, and courses, and to promote networking amongst chaplains.</p>
                  <p className="mt-4">
                    As the number of Muslims living in Britain has increased over the last thirty years, so has the number entering higher education. These young men and women often need pastoral care, which is why a Muslim chaplain’s role is so important.
                  </p>
                  <p className="mt-4">
                    We recognise that these chaplains also need support in a variety of ways in order for them to be as effective as possible in the important role that they do.
                  </p>
                  {/* <p className="mt-4">
                    In addition to that, AMCed organises the following for
                    Muslim chaplains{" "}
                  </p> */}
                  <div  className="mt-4" style={{fontSize : "15px",fontWeight:500}}>
                    <ul>
                        <li>They must have a thorough understanding in the educational institution in which they work.</li>
                        <li className="mt-2">They have to be versed in the social issues that are prevalent in a multi-faith, multi-cultural Britain.</li>
                        <li className="mt-2">They should adhere to a set of professional standards.</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row mt-5 gx-md-5 aboutus-row">
              <div className="col-12 col-lg-6 mb-4 mb-lg-0">
                <div className="section-info text-left p-0">
                  <h1 className="h3">Our Vision</h1>
                  <div className="mt-4" style={{fontSize : "15px",fontWeight:500}}>
                    <ul className="ml-0 ps-4">
                        <li> Provide support and the development of Muslim Chaplains to better serve the education sector</li>
                        <li className="mt-2">Demonstrate the value of Muslim chaplaincy practice in education</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-6">
                <div className="section-info text-left p-0">
                  <h1>Our Objectives</h1>
                  <div className="mt-4" style={{fontSize : "15px",fontWeight:500}}>
                    <ul className="ml-0 ps-4">
                        <li>To develop and implement strategies to enhance the support offered to Muslim chaplains </li>
                        <li className="mt-2">To develop strategies to market and promote AMCed activities </li>
                        <li className="mt-2">To review and develop the management of AMCed resources, processes and procedures </li>
                        <li className="mt-2">To develop and strengthen collaboration with other organisations </li>
                    </ul>
                  </div>
                </div>
              </div>
              </div>


            </div>
          </div>
        </section>
        {/* End About Section */}
        {/* ======= Latest Event Section ======= */}
        {/* {
          eventList && eventList?.length > 0 &&
          <section id="events" className="events aboutEvents">
            <div className="section-title d-flex justify-content-center flex-column align-items-center">
              <h1 className="">Latest Events</h1>
            </div>
            <div className="container-lg">
              <div className={`row justify-content-center event-row`}>
                <EventList eventList={eventList} loading={loading} />
              </div>
            </div>
          </section>
        } */}

        {/* End Latest Event Section */}

        {/* Start Role Section */}
        <section id="role" className="role p-0">
          <div className="container h-100">
            <div className="section-info p-0 d-flex justify-content-center justify-content-md-between flex-column flex-md-row align-items-center h-100">
              <h1 className="text-white">What role does AMCed play?</h1>
              <p className="text-white mt-5 mt-md-0">
                In order that all Muslim chaplains have the same standards and
                goals, AMCed provides a network for them so they can share
                information and ideas, and access training that addresses the
                above points.
              </p>
            </div>
          </div>
        </section>
        {/* End Role Section */}

        {/* Start Support Section */}
        <section id="support" className="support p-0">
          <div className="container-fluid p-0">
            <div className="row align-items-center m-0 p-0">
              <div className="col-12 d-none d-md-block col-md-5 col-lg-4 px-0">
                <img className="img-fluid" src={SUPPORT} alt="" />
              </div>
              <div className="d-none col-lg-2 d-lg-block" />
              <div className="col-12 col-md-6 col-lg right-col">
                <div className="section-info px-4 py-5 px-md-0">
                  <h1 className="">How can AMCed support me?</h1>
                  <p className="mt-4">
                    As a Muslim chaplain, whether you’re new, experienced,
                    trained or untrained, AMCed is here to help you.
                  </p>
                  <p className="mt-4">
                    From the moment you join you will be able to access a wide
                    network of other Muslim chaplains, from across England. This
                    will help you in learning and sharing good practice.
                  </p>
                  <p className="mt-4">
                    In addition to that, AMCed organises the following for Muslim chaplains :
                  </p>
                  <div class="mt-3" style={{fontSize:"15px",fontWeight:500}}>
                  <ul>
                    <li className="mt-1">Forums</li>
                    <li className="mt-1">Workshops</li>
                    <li className="mt-1">Seminars</li>
                    <li className="mt-1">Conferences</li>
                    <li className="mt-1">Consultancy</li>
                    <li className="mt-1">Mentoring scheme</li>
                    <li className="mt-1">Shadowing scheme</li>
                  </ul>
                </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* End Support Section */}

        {/* Start Aim Section*/}
        <section id="aims" className="aims pt-0">
          <div className="container-fluid p-0">
            <div className="aims-info">
              <h1 className="pt-4 pt-md-5">Working towards a better Muslim Chaplaincy</h1>
              {/* <p className="">
                Lorem Ipsum is simply dummy text of the printing and.Lorem Ipsum
                is simply dummy text of the printing and.
              </p> */}
            </div>
            <div className="aims-info-left">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-left">
                    <h1 className="">AMCed aims to</h1>
                  </div>
                  <div className="col-12 col-md-12 col-lg-8">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="card mb-3">
                          <div className="card-body">
                            <p className="card-text">
                              Give Muslim chaplains across the UK a voice,
                              advice and support.
                            </p>
                          </div>
                        </div>
                        <div className="card mb-3">
                          <div className="card-body">
                            <p className="card-text">
                              Raise the professional standards of chaplains by
                              offering specific training in pastoral and
                              spiritual care as well as listening and
                              communication skills, dialogue, presentation,
                              facilitation, mediation and monitoring.
                            </p>
                          </div>
                        </div>
                        <div className="card mb-3">
                          <div className="card-body">
                            <p className="card-text">
                              Ensure chaplains represent Islam effectively so
                              that they are equipped to dispel any
                              preconceptions about the faith that people may
                              have and share their understanding of cultural and
                              faith sensitivities with others. This can include
                              training in equality, culture, faith and
                              diversity.
                            </p>
                          </div>
                        </div>
                        <div className="card mb-3">
                          <div className="card-body">
                            <p className="card-text">
                              Promote the concept of chaplains to the community,
                              both Muslim and non-Muslim, in order to gain
                              recognition, acknowledgement and support.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="card mb-3">
                          <div className="card-body">
                            <p className="card-text">
                              Undertake curriculum development and research to
                              make current chaplaincy training even better and
                              contribute to articles, research papers, journals
                              and resources in the area of equality, culture,
                              faith, diversity and community cohesion.
                            </p>
                          </div>
                        </div>
                        <div className="card mb-3">
                          <div className="card-body">
                            <p className="card-text">
                              Provide chaplains who can lead discussion groups
                              and deliver presentations within places of higher
                              education
                            </p>
                          </div>
                        </div>
                        <div className="card mb-3">
                          <div className="card-body">
                            <p className="card-text">
                              Encourage all chaplains to actively take part in
                              supporting students. Sometimes chaplains have felt
                              isolated by the lack of support they get from
                              institutions, particularly in this area, and have
                              reported instances of being undermined.
                            </p>
                          </div>
                        </div>
                        <div className="card mb-3">
                          <div className="card-body">
                            <p className="card-text">
                              Assist in the recruitment and placement of
                              chaplains in the education sector.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Aim Section */}


         {/* ======= Related Videos Section ======= */}

         <section id="media" className="media pt-md-3 pt-0 pb-5">
          <div className="section-title d-flex justify-content-center flex-column align-items-center pb-4 ">
            <h1 style={{color:"var(--portal-primary)"}}>Related Videos</h1>
          </div>
          <div className="container-lg">
            <div className="row justify-content-center">
              <div className="col-12 col-md-6 col-lg-3 mb-3">
                <div
                  className="embed-responsive embed-responsive-1by1 position-relative"
                  style={{ height: 200 }}
                >
                  <iframe
                    className="embed-responsive-item position-absolute h-100 w-100"
                    src="https://player.vimeo.com/video/174544074?h=6b310dad4d"
                    frameBorder={0}
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen=""
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-3">
                <div
                  className="embed-responsive embed-responsive-1by1 position-relative"
                  style={{ height: 200 }}
                >
                  <iframe
                    className="embed-responsive-item position-absolute h-100 w-100"
                    src="https://player.vimeo.com/video/174539100?h=d4280bb0ea"
                    frameBorder={0}
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen=""
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-3">
                <div
                  className="embed-responsive embed-responsive-1by1 position-relative"
                  style={{ height: 200 }}
                >
                  <iframe
                    className="embed-responsive-item position-absolute h-100 w-100"
                    src="https://player.vimeo.com/video/174544031?h=65f76326cb"
                    frameBorder={0}
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen=""
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-3">
                <div
                  className="embed-responsive embed-responsive-1by1 position-relative"
                  style={{ height: 200 }}
                >
                  <iframe
                    className="embed-responsive-item position-absolute h-100 w-100"
                    src="https://player.vimeo.com/video/174539102?h=c5989c991a"
                    frameBorder={0}
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>


        {/* End Related Videos Section */}


      </main>
      {/* End #main */}
    </>
  );
};

export default About;
