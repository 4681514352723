import React, { useEffect, useState } from "react";
import "./article.css";
import PageInfo from "../../components/layout/pageInfo/PageInfo";
import { getList, imageBaseUrl } from "../../helper";
import ArticleList from "./ArticleList";

const Article = ({ image }) => {
  const [articleList, setArticleList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getNewsData();
  }, []);

  const getNewsData = async () => {
    try {
      setLoading(true);
      const url = '/admin/get/article/v1?limit=&neId=';
      const data = await getList(url);
      setArticleList(data.data);
      setLoading(false);
    } catch (error) {
      console.error("List error", error);
      setLoading(false);
    }
  };
  return (
    <>
      <main id="main">
        <PageInfo
          title="Articles"
          imgUrl={`${imageBaseUrl}${image}`}
          description="View the latest articles below"
        />

        <section id="articles" className="articles">
          <div className="section-title d-flex justify-content-center flex-column align-items-center">
            <h1 className="">Latest Articles</h1>
            {/* <p className="">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum
            </p> */}
          </div>
          <div className="container-lg">
            <div className="row justify-content-center articles-row">
              <ArticleList articleList={articleList} loading={loading} />
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Article;
