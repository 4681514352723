import React, { useEffect, useState } from 'react'
import { getList, imageBaseUrl } from '../helper';
import Loader from '../lottie/Loader';
import Arrow from "../assets/img/purposes/arrow.svg";
import { Link } from 'react-router-dom';

const PurposeList = ({ limit }) => {
  const [purposeList, setPurposeList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPurposeData(limit);
  }, []);

  const getPurposeData = async (limit) => {
    try {
      setLoading(true);
      const url = `/admin/get/purpose/v1?limit=${limit}`;
      const data = await getList(url);
      setPurposeList(data.data);
      setLoading(false);
    } catch (error) {
      console.error("List error", error);
      setLoading(false);
    }
  };

  return (
    loading ?
      <Loader /> :
      purposeList && purposeList.length > 0 && purposeList.map(l => (
        <Link to={`/purposeDtl/${l._id}`} className="card purpose-card p-0 border-start-0 border-end-0 p-0" key={l._id}>
          <img src={`${imageBaseUrl}${l.backgroundImage}`} className="card-img card-bg-img m-0 p-0 border-0" alt="" />
          <div className="card-img-overlay p-0">
            <div className="left d-flex justify-content-center h-100 flex-column px-3 px-md-4">
              <h5 className="card-title">{l.title}</h5>
              <p className="card-text">
                {l.smallDescription}
              </p>
            </div>
            <img className="img d-none d-lg-block" alt="" src={`${imageBaseUrl}${l.displayImage}`} />
            <div className="right">
              <img src={Arrow} alt="" />
            </div>
          </div>
        </Link>
      ))
  )
}

export default PurposeList;