import React from "react";
import "./privacy.css";


const Privacy = ({ image }) => {

    return (
        <main id="main">
          <section id="privacy" className="privacy">
            <div className="container-lg py-4 py-md-5">
                <h1 className="text-left section-title">Privacy and Cookies</h1>
                <p className="section-content">

                </p>
            </div>
          </section>
        </main>
    );
  };
  
  export default Privacy;
  