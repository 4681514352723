import React, { useEffect, useState } from "react";
import "./viewPurpose.css";
import { useParams } from "react-router-dom";
import { getList, imageBaseUrl } from "../../helper";
import EventList from "../event/EventList";


const ViewPurpose = () => {
  const { id } = useParams();
  const [eventList, setEventList] = useState([]);
  const [record, setRecord] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPurposeDetails();
    getEventData();
  }, []);

  const getPurposeDetails = async () => {
    try {
      const url = `/admin/get/purpose/${id}/v1`;
      const data = await getList(url);
      setRecord(data.data);
    } catch (error) {
      console.error("List error", error);
    }
  };

  const getEventData = async () => {
    try {
      setLoading(true);
      const url = `/admin/get/event/v1?limit=3&neId=`;
      const data = await getList(url);
      setEventList(data.data);
      setLoading(false);
    } catch (error) {
      console.error("List error", error);
      setLoading(false);
    }
  };

  return (
    <>
      <main id="main">
        {/* ======= Page Info ======= */}
        <section id="view-pageinfo" className="view-pageinfo position-relative">
          <div className="container d-flex justify-content-between align-items-center py-4 py-lg-0 position-relative h-100">
            <div className="left">
              <h1>{record.title}</h1>
              <p>
                {record.smallDescription}
              </p>
            </div>
            <img
              src={`${imageBaseUrl}${record.displayImage}`}
              alt=""
              className="page-info-img d-none d-lg-block shadow"
            />
          </div>
          <img
            src={`${imageBaseUrl}${record.backgroundImage}`}
            alt=""
            className="d-lg-none page-info-bg-img position-absolute"
          />
        </section>
        {/* End Page Info */}

        <section className="description pb-0" id="description">
          <div className="container">
            <div className="purpuse-description" dangerouslySetInnerHTML={{ __html: record.description }}>
            </div>
          </div>
        </section>

        {/* ======= Media Section ======= */}
        {
          record?.videos && record?.videos?.length > 0 &&
          <section id="media" className="media pt-2">
            <div className="section-title d-flex justify-content-center flex-column align-items-center pb-4">
              <h1>Related Videos</h1>
            </div>
            <div className="container-lg">
              <div className="row justify-content-center media-row">
                {
                  record?.videos?.map((v, i) => (
                    <div key={i} className="col-12 col-md-6 col-lg-4 mb-3">
                        {/* <div className="card border-0 rounded-0 media-card mb-4 mb-md-0"> */}
                          {/* <video controls style={{ width: "100%", height: "auto" }}>
                            <source src={v} />
                            Your browser does not support the video tag.
                          </video> */}
                          
                        {/* </div> */}
                        {
                          <div class="embed-responsive embed-responsive-1by1 position-relative" style={{height : "200px"}}>
                              <iframe class="embed-responsive-item position-absolute h-100 w-100" src={v} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen=""></iframe>
                          </div>
                        }
                    </div>
                  ))
                }
              </div>
            </div>
          </section>
        }
        {/* End Media Section */}

        {/* ======= Events Section ======= */}
        {
          eventList && eventList?.length > 0 &&
          <section id="events" className="events purposeEvent">
            <div className="section-title d-flex justify-content-center flex-column align-items-center">
              <h1 className="">Latest Events</h1>
            </div>
            <div className="container-lg">
              <div className="row justify-content-center event-row">
                <EventList eventList={eventList} loading={loading} />
              </div>
            </div>
          </section>
        }
        {/* End Events Section */}


      </main>
      {/* End #main */}
    </>
  );
};

export default ViewPurpose;
