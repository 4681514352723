import React, { useEffect, useState } from "react";
import './tag.css';
import { useParams } from "react-router-dom";
import { getList, imageBaseUrl } from "../../helper";
import NewsList from "../news/NewsList";
import ArticleList from "../articles/ArticleList";
import Loader from "../../lottie/Loader";

const TagsList = () => {
  const { id } = useParams();
  const [articleList, setArticleList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [record, setRecord] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, [id]);

  const getData = async () => {
    try {
      setLoading(true);
      const url = `/admin/get/tag/list/${id}/v1`;
      const data = await getList(url);
      setRecord(data.data);
      setNewsList(data.newsList);
      setArticleList(data.articleList);
      setLoading(false);
    } catch (error) {
      console.error("List error", error);
      setLoading(false);
    }
  };

  return (
    <>
      {
        loading ?
          <Loader /> :
          <>
            <main id="main">
              <section id="view-pageinfo" className="view-pageinfo position-relative" style={{ backgroundColor: "#41adb6" }}>
                <div className="container d-flex justify-content-between align-items-center py-4 py-lg-0 position-relative h-100">
                  <div className="left">
                    <h1>{record.name}</h1>
                  </div>
                </div>
              </section>

              {
                newsList && newsList?.length > 0 &&
                <section id="news" className="news">
                  <div className="section-title d-flex justify-content-center flex-column align-items-center">
                    <h1 className="">News</h1>
                  </div>
                  <div className="container-lg">
                    <div className="row justify-content-center news-row">
                      <NewsList newsList={newsList} loading={loading} />
                    </div>
                  </div>
                </section>
              }

              {
                articleList && articleList?.length > 0 &&
                <section id="articles" className="articles">
                  <div className="section-title d-flex justify-content-center flex-column align-items-center">
                    <h1 className="">Articles</h1>
                  </div>
                  <div className="container-lg">
                    <div className="row justify-content-center articles-row">
                      <ArticleList articleList={articleList} loading={loading} />
                    </div>
                  </div>
                </section>
              }

            </main>
          </>
      }
    </>
  );
};

export default TagsList;
