import React, { useEffect, useState } from "react";
import "./home.css";
import "../event/event.css";
import PurposeList from "../../components/PurposeList";
import { getList, imageBaseUrl } from "../../helper";
import EventList from "../event/EventList";
import { Link } from "react-router-dom";


function Home({ image }) {
  const [eventList, setEventList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getEventData();
  }, []);

  const getEventData = async () => {
    try {
      setLoading(true);
      const url = `/admin/get/event/v1?limit=3&neId=`;
      const data = await getList(url);
      setEventList(data.data);
      setLoading(false);
    } catch (error) {
      console.error("List error", error);
      setLoading(false);
    }
  };
  return (
    <>
      {/* ======= Hero Section ======= */}
      <section id="hero" className="hero">
        <div className="hero-container">
          <div
            id="heroCarousel"
            data-bs-interval={5000}
            className="carousel slide carousel-fade"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner" role="listbox">
              <div className="carousel-item active">
                <div className="carousel-container">
                  <div className="carousel-content">
                    <div className="carousel-content-inner">
                      <h2 className="animate__animated animate__fadeInDown">
                        How did <span>AMCed</span> come about ?
                      </h2>
                      <p className="animate__animated animate__fadeInUp">
                        We’re often asked how AMCed came into being, and why it
                        was necessary, so we’ve put together this helpful potted
                        history that will hopefully answer all your questions.
                      </p>
                      <Link className="btn-get-started animate__animated animate__fadeInUp" to="/about">
                        More Details{" "}
                        <i className="bt bi-arrow-right m-0 p-0 ms-1" />
                      </Link>
                      {/* <a
                        href="./abou"
                        className="btn-get-started animate__animated animate__fadeInUp"
                      >
                        More Details{" "}
                        <i className="bt bi-arrow-right m-0 p-0 ms-1" />
                      </a> */}
                    </div>
                  </div>
                  <img src={`${imageBaseUrl}${image}`} alt="" className="hero-bg-img" />
                  <div className="carousel-content-right p-4 col-12 col-md-8 col-lg-4 d-none d-lg-block">
                    <h2 className="mb-2">AMCed</h2>
                    <p className="border-0 p-0 m-0">
                      Rewarding, fulfilling and challenging: Do you believe you
                      have what it takes to become a chaplain ?
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* End Hero */}
      <main id="main">
        <section className="purposes pb-0 mt-md-4" id="purposes">
          {/* <div className="section-title d-flex justify-content-center flex-column align-items-center">
            <h1 className="">Lorem Ipsum is simply dummy text</h1>
            <p className="">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum
            </p>
          </div> */}
          <div className="container-lg">
            <div className="row purposes-row p-0 m-0">
              <PurposeList limit='' />
            </div>
          </div>
        </section>

        {/* ======= Featured Section ======= */}
        {
          eventList && eventList?.length > 0 &&
          <section id="events" className="events mt-3 homeEvent">
            <div className="section-title d-flex justify-content-center flex-column align-items-center">
              <h1 className="text-white">Latest Events</h1>
              {/* <p className="text-white">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum
              </p> */}
            </div>
            <div className="container-lg">
              <div className="row justify-content-center event-row">
                <EventList eventList={eventList} loading={loading} />
              </div>
            </div>
          </section>
        }
        {/* End Featured Section */}
      </main>
      {/* End #main */}
    </>
  );
}

export default Home;
