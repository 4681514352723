import React, { useEffect, useState } from "react";
import "./news.css";
import PageInfo from "../../components/layout/pageInfo/PageInfo";
import NewsList from "./NewsList";
import { getList, imageBaseUrl } from "../../helper";

const News = ({ image }) => {
  const [newsList, setNewsList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getNewsData();
  }, []);

  const getNewsData = async () => {
    try {
      setLoading(true);
      const url = '/admin/get/news/v1?limit=&neId=';
      const data = await getList(url);
      setNewsList(data.data);
      setLoading(false);
    } catch (error) {
      console.error("List error", error);
      setLoading(false);
    }
  };

  return (
    <main id="main">
      <PageInfo
        title="News"
        imgUrl={`${imageBaseUrl}${image}`}
        description="View our latest news below"
      />
      <section id="news" className="news">
        <div className="section-title d-flex justify-content-center flex-column align-items-center">
          <h1 className="">Latest News</h1>
          {/* <p className="px-2">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum
          </p> */}
        </div>
        <div className="container-lg">
          <div className="row justify-content-center news-row">
            <NewsList newsList={newsList} loading={loading} />
          </div>
        </div>
      </section>
    </main>
  );
};

export default News;
