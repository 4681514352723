import React, { useEffect, useState } from "react";
import './articleDetails.css';
import { Link, useParams } from "react-router-dom";
import { getList, imageBaseUrl } from "../../helper";
import ArticleList from "./ArticleList";

const ArticleDetails = () => {
  const { id } = useParams();
  const [articleList, setArticleList] = useState([]);
  const [record, setRecord] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getArticleDetails();
    getNewsData();
  }, [id]);

  const getArticleDetails = async () => {
    try {
      const url = `/admin/get/article/${id}/v1`;
      const data = await getList(url);
      setRecord(data.data);
    } catch (error) {
      console.error("List error", error);
    }
  };

  const getNewsData = async () => {
    try {
      setLoading(true);
      const url = '/admin/get/article/v1?limit=3&neId=' + id;
      const data = await getList(url);
      setArticleList(data.data);
      setLoading(false);
    } catch (error) {
      console.error("List error", error);
      setLoading(false);
    }
  };
  return (
    <>
      <main id="main">
        <section id="view-pageinfo" className="view-pageinfo position-relative">
          <div className="container d-flex justify-content-between align-items-center py-4 py-lg-0 position-relative h-100">
            <div className="left">
              <h1>{record.title}</h1>
              <p>
                {record.shortDescription}
              </p>
            </div>
            <img
              src={`${imageBaseUrl}${record.displayImage}`}
              alt=""
              className="page-info-img d-none d-lg-block shadow"
            />
          </div>
          <img
            src={`${imageBaseUrl}${record.displayImage}`}
            alt=""
            className="d-lg-none page-info-bg-img position-absolute"
          />
        </section>
        <section className="description" id="description">
          <div className="container">
            <div className="description-container" dangerouslySetInnerHTML={{ __html: record.description }}>
            </div>
            {
              record?.masterTags && record.masterTags?.length > 0 &&
              <div className="related-tags pt-4 mt-4">
                <div className="btn-group row p-0 m-0">
                  {
                    record.masterTags.map(tag => (
                      <Link to={`/tags/details/${tag?._id}`} key={tag?._id} className="btn col-12 col-md-1 btn-tag rounded-0 me-md-2 my-1 d-flex align-items-center justify-content-center">
                        <span>{tag?.name}</span>
                      </Link>
                    ))
                  }
                </div>
              </div>
            }

          </div>
        </section>


        {
          articleList && articleList?.length > 0 &&
          <section id="articles" className="articles relatedArticles">
            <div className="section-title d-flex justify-content-center flex-column align-items-center">
              <h1>Related Content</h1>
            </div>
            <div className="container-lg">
              <div className="row justify-content-center articles-row">
                <ArticleList articleList={articleList} loading={loading} />
              </div>
            </div>
          </section>
        }

      </main>
    </>
  );
};

export default ArticleDetails;
