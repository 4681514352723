import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
export const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

export const getList = async (url) => {
    try {
        const response = await axios.get(`${baseUrl}${url}`);
        return response.data;
    } catch (error) {
        console.error("error", error);
    }
};