import React, { useEffect, useState } from 'react'
import { getList, imageBaseUrl } from '../../helper';
import Loader from '../../lottie/Loader';
import { Link } from 'react-router-dom';
import "./articleList.css";


const ArticleList = ({ articleList, loading }) => {
  return (
    loading ?
      <Loader /> :
      articleList && articleList.length > 0 && articleList.map(l => (
        <div className="col-12 col-md-4" key={l._id}>
          <Link to={`/articleDetails/${l._id}`} className="text-decoration-none">
            <div className="card border-0 rounded-0 article-card mb-4">
              <img
                className="card-img-top rounded-0"
                src={`${imageBaseUrl}${l.displayImage}`}
                alt=""
              />
              <div className="card-body d-flex align-items-start">
                {/* <div className="left d-flex align-items-center justify-content-center flex-column">
                  <h6 className="month m-0 p-0">{new Date(l.articleDate).toLocaleString('en-us', { month: 'short' }).toUpperCase()}</h6>
                  <h2 className="date m-0 p-0 text-dark">{('0' + new Date(l.articleDate).getDate()).slice(-2)}</h2>
                  <h6 className="year m-0 p-0">{new Date(l.articleDate).getFullYear()}</h6>
                </div> */}
                <div className="right">
                  <h5 className="card-title">
                    {l.title}
                  </h5>
                  <p className="card-text">
                    {l.shortDescription}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </div>

      ))

  )
}

export default ArticleList;