import React from "react";
import "./pageInfo.css";

function PageInfo({title, description, imgUrl}) {
  return (
    <>
      {/* ======= PageInfo ======= */}
      <section id="pageinfo" className="pageinfo">
        <div className="container d-flex justify-content-between align-items-center py-4 py-md-0">
          <div className="left">
            <h1 className="text-white">{title}</h1>
            <p className="text-white pe-md-5">
            {description}
            </p>
          </div>
          <img
            src={imgUrl}
            alt=""
            className="d-none d-md-block"
          />
        </div>
      </section>

      {/* End PageInfo */}
    </>
  );
}

export default PageInfo;
