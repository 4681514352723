import React, { useState } from "react";
import "./contact.css";
import CONTACT_BG from "../../assets/img/contact_us.png";
import { toast } from 'react-toastify';
import axios from "axios";

const Contact = () => {

  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    message: ""
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/contact/send/email/v1`;
      await axios.post(url, data);
      toast("Thank you for contacting us. We will get back to you soon.");
      setData({
        name: "",
        email: "",
        phone: "",
        company: "",
        message: ""
      });
    } catch (error) {
      console.error("List error", error);
    }
  };

  return (
    <>
      <main id="main" className="contactUs">
        <section id="pageinfo" className="pageinfo">
          <div className="container h-100">
            <div className="row m-0 p-0 justify-content-center h-100">
              <div className="col-12 col-md-6 col-lg-5 left d-flex align-items-center justify-content-center">
                <div className="content m-0 text-center">
                  <h1 className="text-white">Contact Us</h1>
                  {/* <p className="text-white w-100">
                    AMCed is developing Muslim chaplaincy courses at both
                    undergraduate and postgraduate levels for those seeking.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="contact" className="contact">
          <div className="container">
            <div className="row justify-content-center contact-row">
              <div className="col-12 col-lg-7">
                <div className="card rounded-5 p-4">
                  <img className="card-img-top" src={CONTACT_BG} alt="" />
                  <div className="card-body">
                    <form onSubmit={e => handleSubmit(e)}>
                      <div className="row mt-3">
                        <div className="col-12 col-md-6">
                          <label className="form-label small required" style={{fontWeight:450}}>Name</label>
                          <input
                            id=""
                            className="form-control shadow-none mb-3"
                            type="text"
                            name=""
                            // placeholder="Name"
                            autoComplete="off"
                            value={data.name}
                            required
                            onChange={e => setData({ ...data, name: e.target.value })}
                          />
                        </div>
                        <div className="col-12 col-md-6">
                        <label className="form-label small required" style={{fontWeight:450}}>Email</label>
                          <input
                            id=""
                            className="form-control shadow-none mb-3"
                            type="email"
                            inputMode="email"
                            name=""
                            // placeholder="Email"
                            autoComplete="off"
                            value={data.email}
                            required
                            onChange={e => setData({ ...data, email: e.target.value })}
                          />
                        </div>
                        <div className="col-12 col-md-6">
                        <label className="form-label small required" style={{fontWeight:450}}>Phone Number</label>
                          <input
                            id=""
                            className="form-control shadow-none mb-3"
                            type="tel"
                            inputMode="tel"
                            name=""
                            // placeholder="Phone Number"
                            autoComplete="off"
                            value={data.phone}
                            required
                            onChange={e => setData({ ...data, phone: e.target.value })}
                          />
                        </div>
                        <div className="col-12 col-md-6">
                        <label className="form-label small required" style={{fontWeight:450}}>Institution or Organisation</label>
                          <input
                            id=""
                            className="form-control shadow-none mb-3"
                            type="text"
                            name=""
                            // placeholder="Institution or Organisation"
                            autoComplete="off"
                            value={data.company}
                            required
                            onChange={e => setData({ ...data, company: e.target.value })}
                          />
                        </div>
                        <div className="col-12">
                        <label className="form-label small required" style={{fontWeight:450}}>Message</label>
                          <textarea
                            id="my-textarea"
                            className="form-control mb-4 shadow-none"
                            name=""
                            rows={4}
                            // placeholder="Message type here"
                            value={data.message}
                            required
                            onChange={e => setData({ ...data, message: e.target.value })}
                          />
                        </div>
                        <div className="col-12 text-center">
                          <button
                            title="Submit"
                            className="btn shadow-none"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Contact;
