import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../lottie/Loader';
import axios from 'axios';

const Subscribe = () => {

  const [loading, setLoading] = useState(true);
  const { email } = useParams();

  const handleVerifyUser = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/verify/v1`, { email });
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (email) {
      handleVerifyUser();
    }
  }, [email]);

  return (
    <>
      {
        loading ?
          <Loader /> :
          <>
            <div className='container'>
              <div className='row'>
                <div className='col'>
                  <div style={{ textAlign: 'center', marginTop: "10px", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: "50vh", width: "100%" }}>
                    <h1>Amced</h1>
                    <h2>Your email confirmation is successful.</h2>
                    <a href='/'>Return to home page</a>
                  </div>
                </div>
              </div>
            </div>
          </>
      }
    </>
  )
};

export default Subscribe;