import React, { useEffect, useState } from 'react'
import { getList, imageBaseUrl } from '../../helper';
import Loader from '../../lottie/Loader';
import { Link } from 'react-router-dom';
import './newsList.css';

const NewsList = ({ newsList, loading }) => {
  return (
    loading ?
      <Loader /> :
      newsList && newsList.length > 0 && newsList.map(l => (
        <div className="col-12 col-md-4" key={l._id}>
          <Link to={`/newsDetails/${l._id}`} className="text-decoration-none">
            <div className="card border-0 rounded-0 news-card mb-4">
              <img
                className="card-img-top rounded-0"
                src={`${imageBaseUrl}${l.displayImage}`}
                alt=""
              />
              <div className="card-body d-flex">
                {/* <div className="left me-3">
                  <h6 className="month m-0 p-0">{new Date(l.newsDate).toLocaleString('en-us', { month: 'short' }).toUpperCase()}</h6>
                  <h2 className="date m-0 p-0 text-dark">{('0' + new Date(l.newsDate).getDate()).slice(-2)}</h2>
                  <h6 className="year m-0 p-0">{new Date(l.newsDate).getFullYear()}</h6>
                </div> */}
                <div className="right">
                  <h5 className="card-title text-dark">
                    {l.title}
                  </h5>
                  <p className="card-text text-muted">
                    {l.shortDescription}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))
  )
}

export default NewsList;