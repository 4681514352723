import React, { useEffect, useState } from "react";
import './trainingsDetails.css';
import { useParams } from "react-router-dom";
import { getList, imageBaseUrl } from "../../helper";
import TrainingList from "./TrainingList";


const TrainingDetails = () => {
  const { id } = useParams();
  const [record, setRecord] = useState(false);
  const [trainingList, setTrainingList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getNewDetails();
    getTrainingData();
  }, [id]);

  const getNewDetails = async () => {
    try {
      const url = `/admin/get/training/${id}/v1`;
      const data = await getList(url);
      setRecord(data.data);
    } catch (error) {
      console.error("List error", error);
    }
  };

  const getTrainingData = async () => {
    try {
      setLoading(true);
      const url = `/admin/get/training/v1?limit=3&neId=${id}`;
      const data = await getList(url);
      setTrainingList(data.data);
      setLoading(false);
    } catch (error) {
      console.error("List error", error);
      setLoading(false);
    }
  };
  return (
    <>
      <main id="main">
        {/* ======= Page Info ======= */}
        <section id="view-pageinfo" className="view-pageinfo position-relative">
          <div className="container d-flex justify-content-between align-items-center py-4 py-lg-0 position-relative h-100">
            <div className="left">
              <h1>{record.title}</h1>
              <p>
                {record.shortDescription}
              </p>
            </div>
            <img
              src={`${imageBaseUrl}${record.displayImage}`}
              alt=""
              className="page-info-img d-none d-lg-block shadow"
            />
          </div>
          <img
            src={`${imageBaseUrl}${record.displayImage}`}
            alt=""
            className="d-lg-none page-info-bg-img position-absolute"
          />
        </section>
        {/* End Page Info */}

        <section className="description" id="description">
          <div className="container">
            <div className="description-container" dangerouslySetInnerHTML={{ __html: record.description }}>
            </div>

            {/* <div className="related-tags pt-4 mt-4">
              <div className="btn-group row p-0 m-0">
                <a href="#" className="btn col-12 col-md-1 btn-tag rounded-0 me-md-2 my-1 d-flex align-items-center justify-content-center">
                  <span>Aducation</span>
                </a>
                <a href="#" className="btn col-12 col-md-1 btn-tag btn-sm rounded-0 me-md-2 my-1 d-flex align-items-center justify-content-center">
                  <span>Faith</span>
                </a>
                <a href="#" className="btn col-12 col-md-1 btn-tag btn-sm rounded-0 me-md-2 my-1 d-flex align-items-center justify-content-center" >
                  <span>Finance</span>
                </a>
              </div>
            </div> */}

          </div>
        </section>


        {/* ======= Explore Section ======= */}
        <section id="training" className="training relatedTraining">
          <div className="section-title d-flex justify-content-center flex-column align-items-center">
            <h1>Related Content</h1>
          </div>
          <div className="container-lg">
            <div className="row justify-content-center training-row">
              <TrainingList loading={loading} trainingList={trainingList} />
            </div>
          </div>
        </section>
        {/* End Explore Section */}

      </main>
      {/* End #main */}
    </>
  );
};

export default TrainingDetails;
