import React from "react";
import "./resources.css";
import PageInfo from "../../components/layout/pageInfo/PageInfo";
import { imageBaseUrl } from "../../helper";
import img1 from "../../assets/img/resources/image_1.jpg";
import img2 from "../../assets/img/resources/image_2.jpg";
import img3 from "../../assets/img/resources/image_3.png";
import img4 from "../../assets/img/resources/image_4.png";
import img5 from "../../assets/img/resources/image_5.png";
import img6 from "../../assets/img/resources/image_6.png";
import img7 from "../../assets/img/resources/image_7.jpeg";
import img8 from "../../assets/img/resources/image_8.jpeg";
import img9 from "../../assets/img/resources/image_9.jpeg";

const Resources = ({ image }) => {
  return (
    <>
      <main id="main">
        {/* ======= Page Info ======= */}
        <PageInfo
          title="Resources"
          imgUrl={`${imageBaseUrl}${image}`}
          description=""
        />
        {/* End Page Info */}

        <section id="resources" className="resources">
          {/* <div className="section-title d-flex justify-content-center flex-column align-items-center">
            <h1 className="">Resources</h1>
          </div> */}

          <div className="container-lg">
            <div className="row justify-content-center resources-row">
              <div className="col-12">
                <figure>
                  <blockquote className="blockquote">
                    <p className="h6">Rajput, A. H. (2024)</p>
                  </blockquote>
                  <figcaption className="blockquote-footer text-dark">
                    Audit of Muslim Provision at Brunel University London
                    {/* <cite title="Source Title">Source Title</cite> */}
                  </figcaption>
                </figure>

                <figure>
                  <blockquote className="blockquote">
                    <p className="h6">Rajput, A. H. (2024)</p>
                  </blockquote>
                  <figcaption className="blockquote-footer text-dark">
                    Founder and co-editor, ‘Narrative Inquiry Within the Lived
                    Experience of University Students’ (LEUS) (Book series)
                    Leiden, Netherlands: BRILL Publishers
                  </figcaption>
                </figure>
                {/*               
                <p>
                  Rajput, A. H. (2024) Founder and co-editor, ‘Narrative Inquiry
                  Within the Lived Experience of University Students’ (LEUS)
                  (Book series) Leiden, Netherlands: BRILL Publishers
                </p> */}

                <figure>
                  <blockquote className="blockquote">
                    <p className="h6">Rajput, A. H. (2022)</p>
                  </blockquote>
                  <figcaption className="blockquote-footer text-dark">
                    ‘The Lived Experiences of Undergraduate British-Muslim
                    Students in Relation to Their Faith Obligations and Studies
                    in Higher Education’ Unpublished thesis
                  </figcaption>
                </figure>
                {/* <p>
                  Rajput, A. H. (2022) ‘The Lived Experiences of Undergraduate
                  British-Muslim Students in Relation to Their Faith Obligations
                  and Studies in Higher Education’ Unpublished thesis
                </p> */}

                <figure>
                  <blockquote className="blockquote">
                    <p className="h6">Rajput, A. H. (2022)</p>
                  </blockquote>
                  <figcaption className="blockquote-footer text-dark">
                    ‘Muslim Chaplaincy Curriculum: Defining
                    the approach.’ Mauritian Institute of Education, Journal of
                    Education. Vol. 12 (1), 93-103.
                  </figcaption>
                </figure>
                {/* <p>
                  Rajput, A. H. (2022) ‘Muslim Chaplaincy Curriculum: Defining
                  the approach.’ Mauritian Institute of Education, Journal of
                  Education. Vol. 12 (1), 93-103.
                </p> */}
                <figure>
                  <blockquote className="blockquote">
                    <p className="h6">Rajput, A. H. (2022)</p>
                  </blockquote>
                  <figcaption className="blockquote-footer text-dark">
                    ‘Reconciling Between Religious
                    Obligations and Studies for Muslim Students in English Higher
                    Education.’ Research in the Social Scientific Study of
                    Religion [RSSSR] Volume 32. In R. Hood, and S.
                    Cheruvallil-Contractor (eds.) Leiden, Netherlands: BRILL
                  </figcaption>
                </figure>
                {/* <p>
                  Rajput, A. H. (2022) ‘Reconciling Between Religious
                  Obligations and Studies for Muslim Students in English Higher
                  Education.’ Research in the Social Scientific Study of
                  Religion [RSSSR] Volume 32. In R. Hood, and S.
                  Cheruvallil-Contractor (eds.) Leiden, Netherlands: BRILL
                </p> */}
                <figure>
                  <blockquote className="blockquote">
                    <p className="h6">Rajput, A. H. (2019)</p>
                  </blockquote>
                  <figcaption className="blockquote-footer text-dark">
                    Professional and Ethical Standards for
                    the Role of Muslim chaplains in Further and Higher Education
                    in the UK
                  </figcaption>
                </figure>
                {/* <p>
                  Rajput, A. H. (2019) Professional and Ethical Standards for
                  the Role of Muslim chaplains in Further and Higher Education
                  in the UK
                </p> */}
                <figure>
                  <blockquote className="blockquote">
                    <p className="h6">Rajput, A. H. (2018)</p>
                  </blockquote>
                  <figcaption className="blockquote-footer text-dark">
                    Fasting During Ramadan and Examinations:
                    A Guidance for Colleges and Universities
                  </figcaption>
                </figure>
                {/* <p>
                  Rajput, A. H. (2018) Fasting During Ramadan and Examinations:
                  A Guidance for Colleges and Universities
                </p> */}
                <figure>
                  <blockquote className="blockquote">
                    <p className="h6">Rajput, A. H. (2017)</p>
                  </blockquote>
                  <figcaption className="blockquote-footer text-dark">
                    A Phenomenological Study of Muslim
                    Chaplains in English Higher Education. Unpublished thesis
                  </figcaption>
                </figure>
                {/* <p>
                  Rajput, A. H. (2017) A Phenomenological Study of Muslim
                  Chaplains in English Higher Education. Unpublished thesis{" "}
                </p> */}
                <figure>
                  <blockquote className="blockquote">
                    <p className="h6">Rajput, A. H. (2015)</p>
                  </blockquote>
                  <figcaption className="blockquote-footer text-dark">
                    The Role of Muslim Chaplains in Higher
                    Education: Should they be doing what they are doing? Practical
                    Theology, 8:3-4, 227-244
                  </figcaption>
                </figure>
                {/* <p>
                  Rajput, A. H. (2015) The Role of Muslim Chaplains in Higher
                  Education: Should they be doing what they are doing? Practical
                  Theology, 8:3-4, 227-244
                </p> */}
                <figure>
                  <blockquote className="blockquote">
                    <p className="h6">Rajput, A. H. (2014)</p>
                  </blockquote>
                  <figcaption className="blockquote-footer text-dark">
                    Muslim Prayer Rooms Report, University of
                    Roehampton
                  </figcaption>
                </figure>
                {/* <p>
                  Rajput, A. H. (2014) Muslim Prayer Rooms Report, University of
                  Roehampton
                </p> */}
                <figure>
                  <blockquote className="blockquote">
                    <p className="h6">Rajput, A. H. (2010)</p>
                  </blockquote>
                  <figcaption className="blockquote-footer text-dark">
                    Chaplaincy review at the university of
                    Manchester and Manchester Metropolitan
                  </figcaption>
                </figure>
                {/* <p>
                  Rajput, A. H. (2010) Chaplaincy review at the university of
                  Manchester and Manchester Metropolitan{" "}
                </p> */}
              </div>
              <div className="col-12">
                <div className="section-title d-flex justify-content-center flex-column align-items-center pb-4 mt-5">
                  <h1 style={{ color: "var(--portal-primary)" }}>Related Images</h1>
                </div>
              </div>
              <div className="col-12">
                <div className="row justify-content-center publications-imgs gy-3">
                  
                  <div className="col-12 col-md-4 col-lg-3 mb-3">
                    <a href={img1} target="_blank" rel="noreferrer">
                      <img src={img1} className="img-thumbnail" alt="AMCed" />
                    </a>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 mb-3">
                    <a href={img2} target="_blank" rel="noreferrer">
                      <img src={img2} className="img-thumbnail" alt="AMCed" />
                    </a>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 mb-3">
                    <a href={img3} target="_blank" rel="noreferrer">
                      <img src={img3} className="img-thumbnail" alt="AMCed" />
                    </a>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 mb-3">
                    <a href={img4} target="_blank" rel="noreferrer">
                      <img src={img4} className="img-thumbnail" alt="AMCed" />
                    </a>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 mb-3">
                    <a href={img5} target="_blank" rel="noreferrer">
                      <img src={img5} className="img-thumbnail" alt="AMCed" />
                    </a>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 mb-3">
                    <a href={img6} target="_blank" rel="noreferrer">
                      <img src={img6} className="img-thumbnail" alt="AMCed" />
                    </a>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 mb-3">
                    <a href={img7} target="_blank" rel="noreferrer">
                      <img src={img7} className="img-thumbnail" alt="AMCed" />
                    </a>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 mb-3">
                    <a href={img8} target="_blank" rel="noreferrer">
                      <img src={img8} className="img-thumbnail" alt="AMCed" />
                    </a>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3 mb-3">
                    <a href={img9} target="_blank" rel="noreferrer">
                      <img src={img9} className="img-thumbnail" alt="AMCed" />
                    </a>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* End #main */}
    </>
  );
};

export default Resources;
