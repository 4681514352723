import React, { useEffect, useState } from "react";
import "./event.css";
import PageInfo from "../../components/layout/pageInfo/PageInfo";
import EventList from "./EventList";
import { getList, imageBaseUrl } from "../../helper";

const Event = ({ image }) => {
  const [eventList, setEventList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getEventData();
  }, []);

  const getEventData = async () => {
    try {
      setLoading(true);
      const url = `/admin/get/event/v1?limit=&neId=`;
      const data = await getList(url);
      setEventList(data.data);
      setLoading(false);
    } catch (error) {
      console.error("List error", error);
      setLoading(false);
    }
  };

  return (
    <>
      <main id="main">
        <PageInfo
          title="Events"
          imgUrl={`${imageBaseUrl}${image}`}
          description="Below is a list of our forthcoming events"
        />
        <section id="events" className="events">
          <div className="section-title d-flex justify-content-center flex-column align-items-center">
            <h1 className="">Events</h1>
            {/* <p className="">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum
            </p> */}
          </div>
          <div className="container-lg">
            <div className="row justify-content-center event-row">
              <EventList eventList={eventList} loading={loading} />
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Event;
