import React from 'react';
import Lottie from "lottie-react";
import Data from "./animations/loader.json";
import "./animation.css";

const Loader = () => {
  return (
      <div className='data'>
        <Lottie style={{ width: "200px", height: "200px" }} animationData={Data} loop={true} />
      </div>
  )
}

export default Loader;