import React, { useEffect, useState } from "react";
import "./training.css";
import PageInfo from "../../components/layout/pageInfo/PageInfo";
import { getList, imageBaseUrl } from "../../helper";
import TrainingList from "./TrainingList";

const Training = ({ image }) => {
  const [trainingList, setTrainingList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTrainingData();
  }, []);

  const getTrainingData = async () => {
    try {
      setLoading(true);
      const url = `/admin/get/training/v1?limit=&neId=`;
      const data = await getList(url);
      setTrainingList(data.data);
      setLoading(false);
    } catch (error) {
      console.error("List error", error);
      setLoading(false);
    }
  };

  return (
    <>
      <main id="main">
        {/* ======= Page Info ======= */}
        <PageInfo
          title="Training &amp; CPD"
          imgUrl={`${imageBaseUrl}${image}`}
          description="AMCed is developing Muslim chaplaincy courses at both undergraduate and postgraduate levels for those seeking a more academic approach. These courses are for the professional development of Muslim Chaplains, faith leaders and anyone who is involved in community or youth work. All course information will be provided including pre-requisites, intended outcomes, contents, length and the dates."
        />
        {/* End Page Info */}
        {/* ======= Featured Section ======= */}
        <section id="training" className="training">
          <div className="section-title d-flex justify-content-center flex-column align-items-center">
            <h1 className="">Training & CPD</h1>
            {/* <p className="">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum
            </p> */}
          </div>

          <div className="container-lg">
            <div className="row justify-content-center training-row">
              <TrainingList loading={loading} trainingList={trainingList} />
            </div>
          </div>

        </section>
        {/* End Featured Section */}
      </main>
      {/* End #main */}
    </>
  );
};

export default Training;
