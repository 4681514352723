import React, { useState } from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

function Footer() {

  const [email, setEmail] = useState("");

  const handleSubscribe = async (e) => {
    e.preventDefault();
    if (!email) {
      toast("Please Enter email");
      return;
    }
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(email)) {
      toast("Please Enter valid email");
      return;
    }
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/subscribe/v1`, { email });
      toast("We have sent confirmation Email");
      setEmail("");
    } catch (error) {
      console.error("error", error);
      if (error?.response?.data?.message) {
        toast(error.response.data.message);
      }
      setEmail("");
    }
  };

  return (
    <>
      {/* ======= Footer ======= */}
      <footer id="footer" className="">
        <div className="footer-newsletter">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center d-flex align-items-center flex-column">
                <h1 className=" col-lg-8 newsletter-heading">
                  {/* Join 3000+ participants worldwide and achieve your dream! */}
                  Sign up for updates
                </h1>
                <form onSubmit={e => handleSubscribe(e)} className="col-12 col-lg-8">
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      placeholder="Email here"
                      aria-label="Email here"
                      aria-describedby="my-addon"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                    <div className="input-group-append">
                      <input
                        type="submit"
                        className="btn"
                        defaultValue="Join us now !"
                        value="Join us now!"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-top">
          <div className="container container-lg">
            <div className="row justify-content-center">
              <div className="col-md-4 col-lg-3 footer-info mb-4">
                <h4>About AMCed</h4>
                <p>
                Association of Muslim Chaplains in Education is an independent, national non-profit organisation which provides support, advice and training for Muslim chaplains working in the education sector.
                </p>
              </div>
          
              <div className="col-md-4 col-lg-3 footer-contact mb-4">
                <h4>Contact Us</h4>
                <p>
                  Email : info@amced.org.uk
                </p>
                {/* <p>
                  Technology Inc.
                  <br />
                  90B Wherever Street
                  <br />
                  99122 Indonesia <br />
                  <br />
                </p> */}
              </div>
              <div className="col-md-2 footer-contact mb-4">
                <h4>Join Us</h4>
                <div className="join-us mb-2 mt-2">
                  <a title="Click to Join Us" className="btn btn-join-us " href="https://docs.google.com/forms/d/e/1FAIpQLSdpOTgM2-AP-A0kZSDHC6L7ocY6F1kRKyM6gMy1by16qqdE9g/viewform?pli=1" target="_blank">Join Now</a>
                  <a title="Click to Join WhatsApp" className="btn btn-join-us ms-1 ms-md-0 mt-md-2" href="https://docs.google.com/forms/d/1R-a_R6RLKSa9fUC2l3UfM7K5PD0UpPjuSEt9geE_vgo/edit" target="_blank">Join WhatsApp</a>
                </div>
                
              </div>
              <div className="col-md-2 footer-contact mb-2">
                <h4>Follow Us</h4>
                {/* <p>
                  <strong>Phone:</strong> +1 234 778 991
                  <br />
                  <strong>Email:</strong> hello@woc.com
                  <br />
                </p> */}
                <div className="social-links mt-2">
                  <a href="https://www.facebook.com/uvesh.imam?mibextid=dGKdO6" target="_blank" className="facebook">
                    <i className="bx bxl-facebook" />
                  </a>
                  {/* <a href="#" className="twitter">
                    <i className="bx bxl-twitter" />
                  </a> */}
                  <a href="https://www.linkedin.com/company/uv-techsoft/" target="_blank" className="linkedin">
                    <i className="bx bxl-linkedin" />
                  </a>
                  {/* <a href="#" className="instagram">
                    <i className="bx bxl-instagram" />
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="nav justify-content-center footer-nav">
          <Link className="nav-link" to="/">
            Home
          </Link>
          <Link className="nav-link" to="/about">
            About us
          </Link>
          <Link className="nav-link" to="/news">
            News
          </Link>
          <Link className="nav-link" to="/article">
            Articles
          </Link>
          <Link className="nav-link" to="/training">
            Training &amp; CPD
          </Link>
          <Link className="nav-link" to="/events">
            Events
          </Link>
          
          <Link className="nav-link" to="/resources">
            Resources
          </Link>
          {/* <Link className="nav-link" to="/publishing">
            Publishing
          </Link> */}
        </nav>
        <div className="m-0 bg-light d-flex p-3 p-md-0 px-md-3 align-items-center justify-content-center flex-column flex-md-row justify-content-md-between">
          <small className="text-dark text-center">
            © Association of Muslim Chaplains in Education { new Date().getFullYear()}
          </small>
          <div className="privacy">
            <nav className="nav justify-content-center">
              <a className="nav-link px-2 text-dark" href="/privacy-and-cookies">
                <small>Privacy and cookies</small>
              </a>
              <a className="nav-link px-2 text-dark" href="/terms-of-use">
                <small>Terms of use</small>
              </a>
              <a className="nav-link px-2 text-dark" href="/accessibility">
                <small>Accessibility</small>
              </a>
            </nav>
          </div>
          {/* <div class="credits">
              Designed & Developed by <a href="https://bootstrapmade.com/">Vasim Davada & Sajjad Ranasariya</a>
            </div> */}
        </div>
      </footer>

      {/* End Footer */}
    </>
  );
}

export default Footer;
