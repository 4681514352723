import React, { useEffect, useState } from 'react'
import { getList, imageBaseUrl } from '../../helper';
import Loader from '../../lottie/Loader';
import { Link } from 'react-router-dom';
import './trainingsDetails.css';

const TrainingList = ({ trainingList, loading }) => {
  return (
    loading ?
      <Loader /> :
      trainingList && trainingList.length > 0 && trainingList.map(l => (
        <div className="col-12 col-md-6 col-lg-4" key={l._id}>
          <Link to={`/trainingDetails/${l._id}`} className="text-decoration-none">
            <div className="card border-0 rounded-0 training-card mb-4">
              <img
                className="card-img-top rounded-0"
                src={`${imageBaseUrl}${l.displayImage}`}
                alt=""
              />
              <div className="card-body d-flex">
                <div className="right">
                  <h5 className="card-title text-dark">
                    {l.title}
                  </h5>
                  <p className="card-text text-muted">
                    {l.shortDescription}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))
  )
}

export default TrainingList;