import React, { useEffect, useState } from "react";
import './eventDetails.css';
import { useParams } from "react-router-dom";
import { getList, imageBaseUrl } from "../../helper";
import EventList from "./EventList";
import moment from "moment";


const EventDetails = () => {
  const { id } = useParams();
  const [eventList, setEventList] = useState([]);
  const [record, setRecord] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getEventData();
  }, []);

  useEffect(() => {
    getEventDetails();
  }, [id]);

  const getEventDetails = async () => {
    try {
      const url = `/admin/get/one/event/${id}/v1`;
      const data = await getList(url);
      setRecord(data.data);
    } catch (error) {
      console.error("List error", error);
    }
  };

  const getEventData = async () => {
    try {
      setLoading(true);
      const url = `/admin/get/event/v1?limit=3&neId=${id}`;
      const data = await getList(url);
      setEventList(data.data);
      setLoading(false);
    } catch (error) {
      console.error("List error", error);
      setLoading(false);
    }
  };

  return (
    <>
      <main id="main">
        <section id="view-pageinfo" className="view-pageinfo position-relative">
          <div className="container d-flex justify-content-between align-items-center py-4 py-lg-0 position-relative h-100">
            <div className="left">
              <h1>{record.title}</h1>
              <p>
                {record.shortDescription}
              </p>
            </div>
            <img
              src={`${imageBaseUrl}${record.displayImage}`}
              alt=""
              className="page-info-img d-none d-lg-block shadow"
            />
          </div>
          <img
            src={`${imageBaseUrl}${record.displayImage}`}
            alt=""
            className="d-lg-none page-info-bg-img position-absolute"
          />
        </section>
        <section className="description" id="description">
          <div className="container">
            <div className="description-container" dangerouslySetInnerHTML={{ __html: record.description }}>
            </div>
            <div className="row eventInfo justify-content-center">
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card mb-4 event-info-card shadow-sm p-4">
                  <div className="card-body text-center">
                    <i className="bx bx-user card-icon" />
                    <h5 className="card-title">Organiser</h5>
                    <p className="card-text mt-3 text-secondary">
                      {record?.organizer ? record.organizer : "To be confirmed"}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-4">
                <div className="card mb-4 event-info-card shadow-sm p-4">
                  <div className="card-body text-center">
                    <i className="bx bx-calendar-event card-icon" />
                    <h5 className="card-title">Date & Time</h5>
                    {/* <p className="card-text mt-4 text-secondary">
                      From Date - {new Date(record.startDateTime).toLocaleString()}
                    </p>
                    <p className="card-text mt-4 text-secondary">
                      To Date &nbsp;&nbsp; - {new Date(record.endDateTime).toLocaleString()}
                    </p> */}
                    {

                    }
                    {
                      record.startDateTime ?
                        <>
                          <p className="card-text mt-3 text-secondary m-0">
                            {moment(new Date(record.startDateTime)).format('DD MMM YYYY, h:mm A')}
                          </p>
                          {
                            record.endDateTime &&
                            <>
                              <p className="text-center mt-2 text-secondary m-0">to</p>
                              <p className="card-text mt-2 text-secondary m-0">
                                {moment(new Date(record.endDateTime)).format('DD MMM YYYY, h:mm A')}
                              </p>
                            </>
                          }
                        </> : <p className="card-text mt-3 text-secondary m-0">To be confirmed</p>
                    }
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-4">
                <div className="card mb-4 event-info-card shadow-sm p-4">
                  <div className="card-body text-center">
                    <i className="bx bx-map card-icon" />
                    <h5 className="card-title">Location</h5>
                    <p className="card-text mt-3 text-secondary">
                      {record?.location ? record.location : "To be confirmed"}
                    </p>
                  </div>
                </div>
              </div>

            </div>
            {/* <div className="related-tags pt-4 mt-4">
              <div className="btn-group row p-0 m-0">
                <a href="#" className="btn col-12 col-md-1 btn-tag rounded-0 me-md-2 my-1 d-flex align-items-center justify-content-center">
                  <span>Aducation</span>
                </a>
                <a href="#" className="btn col-12 col-md-1 btn-tag btn-sm rounded-0 me-md-2 my-1 d-flex align-items-center justify-content-center">
                  <span>Faith</span>
                </a>
                <a href="#" className="btn col-12 col-md-1 btn-tag btn-sm rounded-0 me-md-2 my-1 d-flex align-items-center justify-content-center" >
                  <span>Finance</span>
                </a>
              </div>
            </div> */}

          </div>
        </section>


        {
          eventList && eventList?.length > 0 &&
          <section id="events" className="events eventDtlEvents">
            <div className="section-title d-flex justify-content-center flex-column align-items-center">
              <h1>Related Content</h1>
            </div>
            <div className="container-lg">
              <div className="row justify-content-center event-row">
                <EventList eventList={eventList} loading={loading} />
              </div>
            </div>
          </section>
        }

      </main>
    </>
  );
};

export default EventDetails;
