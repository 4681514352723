import React from "react";
import "./accessibility.css";


const Accessibility = ({ image }) => {

    return (
        <main id="main">
          <section id="accessibility" className="accessibility">
            <div className="container-lg py-4 py-md-5">
                <h1 className="text-left section-title">Accessibility</h1>
                <p className="section-content">
                </p>
            </div>
          </section>
        </main>
    );
  };
  
  export default Accessibility;
  