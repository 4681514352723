import React, { useEffect, useState } from 'react';
import './App.css';
import {
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import Home from './views/home/Home';
import About from './views/about/About';
import News from './views/news/News';
import Article from './views/articles/Article';
import Event from './views/event/Event';
import Training from './views/training/Training';
import Contact from './views/contact/Contact';
import ViewPurpose from './views/purpose/ViewPurpose';
import NewsDetails from './views/news/NewsDetails';
import { getList } from './helper';
import Loader from './lottie/Loader';
import ArticleDetails from './views/articles/ArticleDetails';
import EventDetails from './views/event/EventDetails';
import TrainingDetails from './views/training/TrainingDetails';
import TagsList from './views/tagsList/TagsList';
import Accessibility from './views/accessibility/Accessibility';
import Terms from './views/terms/Terms';
import Privacy from './views/privacy/Privacy';
import Subscribe from './views/subscribe/Subscribe';
import Header from './components/layout/header/Header';
import Footer from './components/layout/footer/Footer';
import Publications from './views/publications/Resources';
import Resources from './views/publications/Resources';

const App = () => {

  const [imageData, setImageData] = useState({});
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.keys(imageData)?.length < 1) {
      getAllImages();
    }
  }, [pathname]);

  const getAllImages = async () => {
    try {
      setLoading(true);
      const url = `/all/page/images/v1`;
      const data = await getList(url);
      setImageData(data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("error", error);
    }
  };


  console.log('imageData', imageData)

  return (
    <>
      {
        loading ?
          <Loader /> :
          <Routes>
            <Route path='/' element={
              <>
                <Header />
                <Home image={imageData?.heroImage} />
                <Footer />
              </>
            } />
            <Route path='/about' element={
              <>
                <Header />
                <About image={imageData?.aboutImage} />
                <Footer />
              </>
            } />
            <Route path='/news' element={
              <>
                <Header />
                <News image={imageData?.newsImage} />
                <Footer />
              </>
            } />
            <Route path='/article' element={
              <>
                <Header />
                <Article image={imageData?.articleImage} />
                <Footer />
              </>
            } />
            <Route path='/events' element={
              <>
                <Header />
                <Event image={imageData?.eventImage} />
                <Footer />
              </>
            } />
            <Route path='/training' element={
              <>
                <Header />
                <Training image={imageData?.trainingImage} />
                <Footer />
              </>
            } />
            <Route path='/Resources' element={
              <>
                <Header />
                <Resources image={imageData?.publicationImage} />
                <Footer />
              </>
            } />
            <Route path='/contact' element={
              <>
                <Header />
                <Contact />
                <Footer />
              </>
            } />
            <Route path='/confirm/email/:email' element={
              <>
                <Subscribe />
              </>
            } />
            <Route path='/purposeDtl/:id' element={
              <>
                <Header />
                <ViewPurpose />
                <Footer />
              </>
            } />
            <Route path='/newsDetails/:id' element={
              <>
                <Header />
                <NewsDetails />
                <Footer />
              </>
            } />
            <Route path='/articleDetails/:id' element={
              <>
                <Header />
                <ArticleDetails />
                <Footer />
              </>
            } />
            <Route path='/eventDetails/:id' element={
              <>
                <Header />
                <EventDetails />
                <Footer />
              </>
            } />
            <Route path='/trainingDetails/:id' element={
              <>
                <Header />
                <TrainingDetails />
                <Footer />
              </>
            } />
            <Route path='/tags/details/:id' element={
              <>
                <Header />
                <TagsList />
                <Footer />
              </>
            } />
            <Route path='/accessibility' element={
              <>
                <Header />
                <Accessibility />
                <Footer />
              </>
            } />
            <Route path='/terms-of-use' element={
              <>
                <Header />
                <Terms />
                <Footer />
              </>
            } />
            <Route path='/privacy-and-cookies' element={
              <>
                <Header />
                <Privacy />
                <Footer />
              </>
            } />
          </Routes>
      }
    </>
  )
};

export default App;